var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Introduction',{attrs:{"width":"l","title":"Page not found","actions":[
        {
            title: _vm.$t('app.home'),
            class: 'bg-accent',
            to: '/',
        },
    ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }