<template>
    <div class="introduction py-xxl relative overflow-hidden">
        <div class="c z-5 relative" :class="['w-' + (width || 'xxl')]">
            <div class="w-12 m:w-8 l:w-4 xl:w-3 pt-xxl">
            </div>
            <div class="flex flex-wrap justify-between align-start">
                <div class="w-12 m:w-8 l:w-6 xl:w-4">
                <h2 class="m-0">{{ title }}</h2>
                    <div class="color-secondary pt-r pr-l mb-l" v-html="nl2br(text)" />
                    <Actions :actions="actions ? actions : (action ? [action] : '')" />
                </div>

                <div class="w-12 l:w-6 xl:w-7 introduction__images flex align-stretch m-down:mt-xxl">
                    <div :class="{ 'w-7 mr-r': image2, 'w-12': !image2 }">
                        <div v-if="image1" :class="{ 'ratio-4by2': image2, 'ratio-16by9': !image2 }">
                            <img :src="cdnurl(image1)" :alt="image_caption1" class="object-cover rounded-s" loading="lazy" />
                        </div>
                    </div>
                    <div class="w-5" v-if="image2">
                        <div class="bg-gray-100">
                            <img :src="cdnurl(image2)" :alt="image_caption2" class="object-cover w-12 rounded-s" loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { strings } from 'p5-helpers';

export default {
    props: {
        text: String,
        title: String,
        image1: String,
        image_caption1: String,
        image2: String,
        image_caption2: String,
        width: String,
        actions: Array,
        action: Object,
    },
    methods: {
        nl2br: strings.nl2br,
    }
};
</script>

<style>
    .introduction__images img { display: block; width: 100%; height: 100%; }
    .introduction__images > div:nth-child(2) > div { height: 100%; }

    .introduction::before, .introduction::after {
        content: '';
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: -1rem;
        width: 10%;
        height: 100%;
        background-image: url('/img/leaves_green.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom left;
        z-index: 1;
        opacity: 0.3;
    }
        .introduction::after {
            bottom: auto;
            left: auto;
            right: -2%;
            top: -4rem;
            width: 15%;
            background-position: top right;
            transform: scaleX(-1);
        }

        @media (max-width: 48em) {
            .introduction::before { display: none; }
            .introduction::after {
                width: 25%;
                right: -1rem;
                bottom: -1rem;
            }
        }
</style>
